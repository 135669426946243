export default {
    name: "New",
    created () {
        this.$nextTick(function () {
            this.GetDeployedNewWarran()
        });
    },
    mounted () {

    },
    data () {
        return {
            NewData: [],
        };
    },
    props: ['JudgeIos'],
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 New Warrant ric 数据
        GetDeployedNewWarran: function () {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$$.mibb + "GetDeployedNewWarrant",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.text, (i, c) => {
                            ricArr.push(c.ric)
                        })
                        this.GetMarketData(ricArr)

                    }
                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
        // 获取 New Warrant 详细数据
        GetMarketData: function (ric) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        let num = 0
                        $.each(ric, (i, c) => {
                            this.NewData.push(data[c])
                        })
                        console.log(this.NewData);
                    }

                },
                error: function (error) {
                    console.log(error);
                }
            });
        },
    }
};