export default {
	name: "Movers",
	created () {
		this.$nextTick(function () {
			// 获取 Warrant Movers 的数据
			this.WarrantMoversGetMarketData('topwarrant')
		});
	},
	mounted () {

	},
	props: ['Mindex', 'JudgeIos'],
	data () {
		return {
			WarrantMoversData: [],
			ricName: ''
		};
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取 Warrant Movers 的数据
		WarrantMoversGetMarketData: function (value) {
			$.ajax({
				type: "POST",
				dataType: 'json',
				url: this.$$.mibb + "GetMarketData",
				data: { token: "webkey", type: 'ranking', bidstatus: 0 },
				success: (res) => {
					if (res.status == 'SUCCESS') {
						this.ricName = `(${res[value][0].underlying_ticker}) (${res[value][0].underlying_code}) ${res[value][0].underlying_name}`
						this.WarrantMoversData = res[value]
					}
				},
				error: function (XMLHttpRequest) { }
			});
		},
		// 点击按钮 接受数据更改表格数据
		getDatum: function (index, value) {
			$('.Movers' + this.Mindex).find('button').eq(index).addClass('mark')
			$('.Movers' + this.Mindex).find('button').eq(index).siblings().removeClass('mark')
			this.WarrantMoversGetMarketData(value)
		},
	}
};