export default {
    name: "Bulletin",
    created () {
        this.$nextTick(function () {
            // 获得数据
            this.GetDeployedAnnouncementList()
        });
    },
    mounted () {
        this.$nextTick(function () { })
    },
    props: ['Bindex'],
    data () {
        return {
            dataUl: [],
            dataContent: [],
            optionDataText: ''
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取数据
        GetDeployedAnnouncementList () {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetDeployedAnnouncementList",
                data: { lang: localStorage.getItem('lang') || 1 },
                async: false,
                success: (data) => {
                    if (data.status == "SUCCESS") {
                        console.log(data, 111);
                        if (data.text.length) {
                            this.dataUl = data.text
                            this.optionDataText = this.$$.formatDate(data.text[0].date) + '  ' + data.text[0].topic
                            this.GetDeployedAnnouncement(data.text[0])
                        }
                    }
                }
            })
        },
        GetDeployedAnnouncement (dataC) {
            this.optionDataText = this.$$.formatDate(dataC.date) + '  ' + dataC.topic
            $('#Bulletin').find('.option').hide();
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetDeployedAnnouncement",
                async: false,
                data: { id: dataC.id, lang: localStorage.getItem('lang') || 1 },
                success: (data) => {
                    if (data.status == "SUCCESS") {

                        this.dataContent = data.text[0]
                    }
                }
            })
        },
        optionShow: function () {
            $('.Bulletin' + this.Bindex).find('.option').slideToggle();
        },
    }
};