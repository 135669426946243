export default {
    name: "Highlights",
    created() {
        this.$nextTick(function() {
            this.GetDeployedWarrantOfTheDay()
        });
    },
    mounted() {

    },
    data() {
        return {
            DailyHighlightsDataZong: [],
            DailyHighlightsData: []
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取 Daily Highlights ric 数据
        GetDeployedWarrantOfTheDay: function() {
            let ricArr = []
            $.ajax({
                type: "post",
                url: this.$$.mibb + "GetDeployedWarrantOfTheDay",
                data: { token: "webkey" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        $.each(data.text[0], function(i, c) {
                            if (i.indexOf(i.replace(/ric/gi, "")) > 0) {
                                ricArr[Number(i.replace(/[^0-9]/gi, "")) - 1] = c;
                            }
                        })
                        this.DailyHighlightsGetMarketData(ricArr)
                    }
                },
                error: function(error) {
                    console.log(error);
                }
            });
        },
        // 获取 Daily Highlights 详细数据
        DailyHighlightsGetMarketData: function(ric) {
            $.ajax({
                type: "GET",
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", ric: ric.toString(), type: "warrantdata" },
                dataType: "json",
                success: data => {
                    if (data.status === "SUCCESS") {
                        let num = 0
                        for (let i = 0; i < 10; i++) {
                            if (i % 2 == 0) {
                                this.DailyHighlightsData.push({
                                    name: data[ric[num]].dsply_nmll,
                                    ifNum: Number(data[ric[num]].BID_PCTCHNG) == 0 ? false : true,
                                    variate: data[ric[num]].BID_PCTCHNG.indexOf('-') >= 0 ? false : true,
                                    integer: data[ric[num]].BID_PCTCHNG.indexOf('-') >= 0 ? `-${this.$$.formatNumber(Math.abs(data[ric[num]].BID_PCTCHNG), 1, 0)}%` : `${this.$$.changeRate(this.$$.formatNumber(Math.abs(data[ric[num]].BID_PCTCHNG), 1, 0))}%`,
                                    ric: data[ric[num]].ric,
                                    estimate: true
                                })
                            } else {
                                this.DailyHighlightsData.push({
                                    name: data[ric[num]].underlying_ticker,
                                    ifNum: Number(data[ric[num]].underlying_pctchng) == 0 ? false : true,
                                    variate: data[ric[num]].underlying_pctchng.indexOf('-') >= 0 ? false : true,
                                    integer: data[ric[num]].underlying_pctchng.indexOf('-') >= 0 ? `-${this.$$.formatNumber(Math.abs(data[ric[num]].underlying_pctchng), 1, 0)}%` : `${this.$$.changeRate(this.$$.formatNumber(Math.abs(data[ric[num]].underlying_pctchng), 1, 0))}%`,
                                    price: `${this.$t('WSe.EP')}：${this.$$.curreryRetrun(data[ric[num]].underlying_curr)} ${this.$$.formatNumber(data[ric[num]].exercise_price, 3, 0)}`,
                                    ric: data[ric[num]].underlying_ric,
                                    estimate: false
                                })
                                num++
                            }
                        }
                    }
                    this.DailyHighlightsDataZong[0] = [this.DailyHighlightsData[0], this.DailyHighlightsData[1], this.DailyHighlightsData[1].price]
                    this.DailyHighlightsDataZong[1] = [this.DailyHighlightsData[2], this.DailyHighlightsData[3], this.DailyHighlightsData[3].price]
                    this.DailyHighlightsDataZong[2] = [this.DailyHighlightsData[4], this.DailyHighlightsData[5], this.DailyHighlightsData[5].price]
                    this.DailyHighlightsDataZong[3] = [this.DailyHighlightsData[6], this.DailyHighlightsData[7], this.DailyHighlightsData[7].price]
                    this.DailyHighlightsDataZong[4] = [this.DailyHighlightsData[8], this.DailyHighlightsData[9], this.DailyHighlightsData[9].price]
                    this.$forceUpdate()
                },
                error: function(error) {
                    console.log(error);
                }
            });
        },
    }
};